<template>
    <div>
        <div style="display: flex; justify-content: space-between; align-items: center;">
            <div style="display: flex; gap: 16px; flex-grow: 1; align-items: center;">
                <img src="https://s3iconimages.mymedicine.com.mm/BackButtonWhite.svg" alt="" style="width: 20px; height: 14px; cursor: pointer;" @click="goBack">
                <div class="headerHeading" v-if="showHospitalInfoLoader == false">{{$t("Customer.MySubscription.Package_Details")}}</div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    
    
    
    export default {
        name: 'PackageDetailsHeaderComp',
        data: function() {
            return {
                todayAppointment: [],
                pageLoading: true,
                ongoingAppointment: [],
                bannerURL: '',
                viewDetailsModel: false,
                hospitalName: 'Asia Royal Hospital',
                hospitalLocation: 'Yangon',
                hospitalRegNumber: '1241424',
                phoneNumber: '+95 67980342453',
                reviews: '299',
                doctorCount: 56,
                // fullDescrip/tion: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint.',
                readMore: false,
            };
        },
        computed: {
            showHospitalInfoLoader () {
                return this.$store.state.OPDPackageParticularHospitalState.fetchingHospitalInfo;        
            },
            hospitalData () {
                return this.$store.state.OPDPackageParticularHospitalState.hospitalInfo;        
            },
            fullDescription() {
                return this.$store.state.OPDPackageParticularHospitalState.hospitalInfo.about;        
            },  
            textPreview() {
                return this.fullDescription.slice(0, 100) + '...';
            }
        },
        async mounted() {
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            toggleHospitalDetails() {
                this.viewDetailsModel = !this.viewDetailsModel;
            },
            closeBottomSheet() {
                this.viewDetailsModel = false;
            },
            descriptionPreview() {
    
            }
        },
    };
    
    </script>
    <style  scoped>
    .headerHeading {
        color: white;
        font-weight: 600;
    }
    .v-sheet {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }
    
    .v-dialog__content >>> .v-bottom-sheet {
        box-shadow: none !important;
    }
    .viewHospitalDetailsBottomSheetHeading {
        display: flex;
        justify-content: space-between;
        padding: 12px 16px 12px 16px;
        border-bottom: 1px solid #e8e8eb;
    }
    .viewDetailsBottomContainer {
        display: flex;
        flex-direction: column;
    
    }
    .viewDetailsBody {
        padding: 20px 16px 20px 16px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        
    }
    .hospitalNameContainer {
        display: flex;
        gap: 8px;
    }
    .hospitalLogo {
        max-height: 56px;
        max-width: 56px;
        height: 56px;
        width: 56px;
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
    }
    .hospitalName {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .hospitalAbout {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin-top: 24px;
    }
    .registrationNoContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-radius: 8px;
        border: 1px solid #e0e0e0;
        padding: 8px 16px 8px 16px;
    }
    .registrationNumHeader{
        font-size: 14px;
        font-weight: 500;
        color: #4f4f4f;
    }
    .registrationNumValue{
        font-size: 14px;
        font-weight: 600;
        color: #333333;
    }
    .noOfDoctor {
        padding: 6px 12px 6px 12px;
        gap: 8px;
        border-radius: 50px;
        opacity: 0px; 
        font-size: 14px;
        font-weight: 600;
        color: #1467BF;
        background: #1467BF1A;
        margin-top: 24px;
    }
    
    </style>
    